import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "@emotion/styled"

const CharacterImage = ({ className }) => {
  const {
    file: { image },
  } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "girl-flying.png" }) {
          image: childImageSharp {
            fluid(quality: 90, maxWidth: 300, srcSetBreakpoints: [300]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Image
      className={className}
      fluid={image.fluid}
      role="img"
      aria-label="Flying Girl Character"
    />
  )
}

export const FlyingGirl = styled(CharacterImage)``
